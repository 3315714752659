import React, { useState } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
const Help = () => {
    const [ activeCategory, setActiveCategory ] = useState('Getting Started')
    console.log('acc',activeCategory)
    return (
        <div>
            <Layout>
                <Seo title="Help" description="Help page Ricta Browser" />
                <div className="flex">
                    <div className="min-h-screen z-1 flex flex-row ">
                        <div className="flex flex-col w-full bg-mainColor mr-3">
                            <ul className="flex flex-col mx-4 py-4 pr-4">
                                <li className={`flex ${activeCategory==='Getting Started' ? 'bg-yellowColor text-mainColor' : 'bg-mainColor text-white '} cursor-pointer rounded-lg p-2 flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200`}>
                                    <a onClick={()=>{setActiveCategory('Getting Started')}} >
                                        <span className="text-sm text-white font-medium">Getting started</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>{setActiveCategory('Browser Settings')}} className={`flex ${activeCategory==='Browser Settings' ? 'bg-yellowColor text-mainColor' : 'bg-mainColor text-white '} cursor-pointer  rounded-lg p-2 flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200`}>
                                        <span className="text-sm text-white font-medium">Browser Settings</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>{setActiveCategory('Advanced Browser Usage')}} className={`flex ${activeCategory==='Advanced Browser Usage' ? 'bg-yellowColor text-mainColor' : 'bg-mainColor text-white '} cursor-pointer  rounded-lg p-2 flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200`}>
                                        <span className="text-sm text-white text-left font-medium">Advanced Browser Usage</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>{setActiveCategory('Manage your Licenses')}} className={`flex ${activeCategory==='Manage your Licenses' ? 'bg-yellowColor text-mainColor' : 'bg-mainColor text-white '} cursor-pointer  rounded-lg p-2 flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200`}>
                                        <span className="text-sm text-white text-left font-medium">Managing your Licenses</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>{setActiveCategory('Security & Privacy')}} className={`flex ${activeCategory==='Security & Privacy' ? 'bg-yellowColor text-mainColor' : 'bg-mainColor text-white '} cursor-pointer  rounded-lg p-2 flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200`}>
                                        <span className="text-sm text-white font-medium">Security & Privacy</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>{setActiveCategory('Troubleshooting')}} className={`flex ${activeCategory==='Troubleshooting' ? 'bg-yellowColor text-mainColor' : 'bg-mainColor text-white '} cursor-pointer  rounded-lg p-2 flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200`}>
                                        <span className="text-sm text-white font-medium">Troubleshooting</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>{setActiveCategory('Notifications')}} className={`flex ${activeCategory==='Notifications' ? 'bg-yellowColor text-mainColor' : 'bg-mainColor text-white '} cursor-pointer  rounded-lg p-2 flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200`}>
                                        <span className="text-sm text-white font-medium">Notifications</span>
                                        <span className="ml-auto mr-6 text-sm bg-red-100 rounded-full px-3 py-px text-red-500">5</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>{setActiveCategory('Contact Us')}} className={`flex ${activeCategory==='Contact Us' ? 'bg-yellowColor text-mainColor' : 'bg-mainColor text-white '} cursor-pointer  rounded-lg p-2 flex-row items-center h-12 transform hover:translate-x-2 transition-transform ease-in duration-200`}>
                                        <span className="text-sm text-white font-medium">Contact Us</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="shadow-lg w-full rounded-lg p-4">
                        {activeCategory==='Getting Started' && 'text to show startting'}
                        {activeCategory==='Browser Settings' && 'text to show brpsers'}
                    </div>
                </div>
            </Layout>
        </div>
    )

}
export default Help